import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"

const NosFormationsPage = ({ data }) => (
  <Layout>
    <Seo title="Permis B" breadcrumb={[
      { url: "nos-formations", name: "Nos Formations"},
      { url: "permis-b", name: "Permis B"}
    ]} />
    <Breadcrumb paths={[{url: "/nos-formations/", name:"Nos formations"}, {url: null, name: "Permis B"}]} />
    <h1 className="h1">Permis B</h1>
    <div
      className="text md:w-3/4 html-content"
      dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.permis_b_page }}
    ></div>
  </Layout>
)

export default NosFormationsPage

export const pageQuery = graphql`
  query PermisBQuery {
    strapiNosFormations {
      permis_b_page
    }
  }
`
